import Validator from '../utils/Validator';

Validator.init('form#form-login', {
    login_email: {
        required: true,
        email: true,
        rangelength: [1, 200]
    },
    login_password: {
        required: true,
        rangelength: [6, 100]
    }
});
